import React from 'react'
import gif from '../assets/otter.gif'
import { Link } from 'gatsby'

class Gif3Page extends React.Component {
    
    render() {
        return (
            <div class="giflayout">
                <img src={gif}></img>
                <Link to="surprise" class="button">OK, one last... I promise</Link>
            </div>
        )
    }
}

export default Gif3Page